import axios from "axios";

const API = "servicios-paqueteria";

const ENDPOINTS = {
    getServicios(){
        return axios(`${API}`,)
    },
    getDetalle(idServicio){
        return axios(`${API}/${idServicio}/detalle`,)
    },
    getEnvios(idServicio, params){
        return axios(`${API}/${idServicio}/envios`,{params})
    },
    postServicio(payload){
        return axios.post(`${API}`, payload)
    },
    editEstadoCedis(payload){
        return axios.put(`${API}/cedis/estado`, payload)
    },
    editListaCedis(idServicio, payload){
        return axios.put(`${API}/${idServicio}/cedis`, payload)
    },
    editServicio(idServicio, payload){
        return axios.put(`${API}/${idServicio}/edit`, payload)
    },
    deleteServicio(idServicio){
        return axios.delete(`${API}/${idServicio}/eliminar`)
    },
    deleteCedisServicio(idServicioCedis){
        return axios.delete(`${API}/cedis/${idServicioCedis}/eliminar`)
    },
};

export default ENDPOINTS;
